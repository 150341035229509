const routes = [
  {
    path: ["/", "/home"],
    exact: true,
    component: "Home",
  },
  {
    path: ["/privacy", "/policies/privacy"],
    exact: true,
    component: "Privacy",
  },
  {
    path: ["/terms", "/policies/terms"],
    exact: true,
    component: "Terms",
  },
];

export default routes;

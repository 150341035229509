import { Row, Col } from "antd";
import { withTranslation } from "react-i18next";
import { Image } from "../../common/Image";
import Container from "../../common/Container";
import { MarginContainer, OuterWrapper } from "../../common/Container";
import i18n from "i18next";
import styled from "styled-components";
import { Link } from "react-router-dom";
import MailchimpForm from "../EmbeddedForm/mailchimpSignup";

import {ReactComponent as IG} from '../../img/svg/insta.svg';
import {ReactComponent as TW} from '../../img/svg/twitter.svg';
import {ReactComponent as LI} from '../../img/svg/linkedin.svg';


const Footer = ({ t }: any) => {
  const handleChange = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <>
        <FooterSection>
            <FooterBlock>
                <Title>{t("Learn")}</Title>
                <Large href="https://raheem.org">
                {t("About us")}
                </Large>
                <Large href="https://help.getpatch.org/">
                {t("Help Center")}
                </Large>
                <LargeLink to="/privacy/">
                {t("Privacy")}
                </LargeLink>
                <LargeLink to="/terms/">
                {t("Terms")}
                </LargeLink>
            </FooterBlock>
            <FooterBlock>
                <Title>{t("Contribute")}</Title>
                <Large href="https://raheem.kindful.com/">
                {t("Donate")}
                </Large>
            </FooterBlock>
            <FooterBlock>
                <Title>{t("Connect")}</Title>
                            {/*
                <Large href="https://bit.ly/PATCHsignup">
                    <span className="hideWhenNarrow">Learn more about building care outside of policing with the </span>
                    <Linkish>PATCH Network</Linkish>
                </Large> 
  */}
                <Large href="mailto:info@communityresponse.works">
                {t("Contact us")}
                </Large>
                <SocialLinkContainer>
                    <a
                        href="https://www.instagram.com/response_works/"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="insta"
                        aria-label="insta">
                        <IG fill="#FFFFFF" width="25px" height="25px" />
                    </a>

                    <a
                        href="https://twitter.com/responseworks"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="twitter"
                        aria-label="twitter">
                        <TW fill="#FFFFFF" width="25px" height="25px" />
                    </a>

                    <a
                        href="https://www.linkedin.com/company/community-response-works/"
                        target="_blank"
                        rel="noopener noreferrer"
                        key="linkedin"
                        aria-label="linkedin">
                        <LI fill="#FFFFFF" width="25px" height="25px" />
                    </a>
                </SocialLinkContainer>
            </FooterBlock>
        </FooterSection>
{/*
        <FooterSection className="last">
            <FooterBlock>
            <LargeP>Get the latest updates about Patch delivered to your inbox</LargeP>
            <MailchimpForm  />
            </FooterBlock>
        </FooterSection>
*/}
        <Extra>
                © 2022 Patch | app developed by Raheem. All rights reserved. · 
                Raheem AI, EIN 82-1805718, is a registered 501(c)(3) nonprofit organization.
        </Extra>
    </>
  );
};

export default withTranslation()(Footer);

const FooterSection = styled("footer")`
  background: #53317C;
  position: relative;
  padding: 4rem 6rem;
  display:flex;
  flex-direction: row;
  justify-content:left;
  align-items:flex-start;
  text-align: left;

  &.last {
    padding-top: 0;
  }

  a {
    border-bottom: none;
  }

  @media only screen and (max-width: 1000px) {
    padding: 4rem 6rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 4rem;
    flex-direction: column;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem 2rem;
  }

  h4 {
    margin-bottom: 0.5rem;
  }

`;

const FooterBlock = styled("div")`
  position: relative;
  flex: 1;
  margin: 1rem 1rem 0 0;

  @media only screen and (max-width: 800px) {
    margin: 0;
  }
`;

const Title = styled("h4")`
  font-size: 18px;
  text-transform: capitalize;
  color: rgba(255,255,255,0.75);
  padding-bottom: 0.5rem;

  @media only screen and (max-width: 800px) {
    display: none;
  }
`;

const Extra = styled("section")`
  background: rgba(41,14,74,1);
  color: rgba(255,255,255, 0.5);
  position: relative;
  padding: 2rem 4rem 2rem 6rem;
  display:flex;
  flex-direction: column;
  justify-content:left;
  align-items:left;
  text-align: left;
  border-top: 1px solid rgba(255,255,255, 0.5);

  @media only screen and (max-width: 1000px) {
    padding: 4rem 6rem;
  }

  @media only screen and (max-width: 800px) {
    padding: 4rem;
  }

  @media only screen and (max-width: 600px) {
    padding: 2rem 2rem 1.5rem;
  }
`;

const LargeLink = styled(Link)`
  font-size: 16px;
  color: rgba(255,255,255,0.5);
  cursor: pointer;
  display: block;
  margin-bottom: 0.75rem;
  transition: all 0.1s ease-in-out;
  max-width: max-content;

  &:hover,
  &:active,
  &:focus {
    color: #FFF;
  }

  .hideWhenNarrow {
    display: inline;

    @media only screen and (max-width: 1000px) {
      display: none;
    }

    @media only screen and (max-width: 800px) {
        display: inline;
      }
  }
`;

const Large = styled("a")<any>`
  font-size: 16px;
  color: rgba(255,255,255,0.5);
  cursor: pointer;
  display: block;
  margin-bottom: 0.75rem;
  transition: all 0.1s ease-in-out;
  max-width: max-content;

  &:hover,
  &:active,
  &:focus {
    color: #FFF;
  }

  .hideWhenNarrow {
    display: inline;

    @media only screen and (max-width: 1000px) {
      display: none;
    }

    @media only screen and (max-width: 800px) {
        display: inline;
      }
  }
`;

const LargeP = styled("p")`
  font-size: 16px;
  color: rgba(255,255,255,0.5);
  display: block;
  margin-bottom: 0.75rem;
  max-width: max-content;
`;

const Linkish = styled("span")`
  text-decoration: underline;
  text-underline-offset: 2px;

  @media only screen and (max-width: 1000px) {
    text-decoration: none;
  }

  @media only screen and (max-width: 800px) {
    text-decoration: underline;
  }
`;

const SocialLinkContainer = styled("div")<any>`
  position: relative;
  margin-top: 2rem;

  svg {
    fill: #FFFFFF;
    opacity: .6;
    margin: 0 1.5rem 1.5rem 0;

    &:hover,
    &:active,
    &:focus {
      opacity: 1;
    }
  
  }
`;

const Label = styled("label")`
  font-size: 18px;
  text-transform: capitalize;
  color: rgba(255,255,255,0.75);
  display: block;
  margin-bottom: 1.5rem;
  font-family: "Neuzeit Grotesk Bold", serif;

  @media screen and (max-width: 414px) {
    padding: 1.5rem 0;
    margin-bottom: 1rem;
  }
`;

const LanguageSwitch = styled("div")<any>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;

  &:hover,
  &:active,
  &:focus {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
  }
`;

const LanguageSwitchContainer = styled("div")`
  display: flex;
  justify-content: space-between;
  width: 85px;
`;
